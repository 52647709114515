/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--default {
  $primary: #c1c1c1;
  $secondary: #585858;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $black;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__brand {
    flex-direction: row;
    justify-content: normal;

    h1 {
      margin-right: 0;
      font-size: $fs40;

      @include media-breakpoint-down(md) {
        font-size: $fs32;
      }
    }
  }

  .act-header__logo {
    width: 0;
    height: 0;
  }

  @import "../brand-template";
}
