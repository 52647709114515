/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-order-item {
  margin-bottom: $space3;
  background-color: $white;

  > .row {
    border: 1px solid $order-item-border-color;

    &:not(:first-child) {
      border-top: 0;
    }
  }
}

.act-order-item__col,
.act-order-item__history-col {
  border-left: 2px solid transparent;
}

.act-order-item__col {
  padding: $space3;

  &--first {
    border-left: none;

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;

      > div:last-child {
        padding-bottom: $space3;
        border-bottom: 1px solid $order-item-border-color;
      }
    }
  }
}

.act-order-item__col-label {
  font-weight: bold;
}

.act-order-item__description {
  margin: $space1 0;
  font-weight: bold;

  @include media-breakpoint-up(md) {
    margin: 0 0 $space1 0;
  }
}

.act-order-item__description-detail {
  font-size: $fs11;

  @include media-breakpoint-up(lg) {
    font-size: $fs13;
  }
}

.act-order-item__history-col {
  padding: $space2 $space3;

  @include media-breakpoint-up(lg) {
    padding: $space3;
  }
}

.act-order-item__collapse-row {
  background-color: $order-item-row-bg--dark;
}

.act-order-item__collapse-btn {
  width: 100%;
  padding: $space2 $space3;
  font-size: $fs12;
  font-weight: bold;
  text-align: left;

  @include media-breakpoint-up(lg) {
    font-size: $fs14;
  }

  &,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {

    span {
      z-index: 2;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }
  }

  .act-icon-arrow {
    margin-left: $space2;
  }
}

.act-order-item__history,
.act-order-item__history-row,
.act-order-item__history-col {
  background-color: $body-bg;
}

.act-order-item__history {
  width: 100%;
}

.act-order-item__history-row {
  border-right: 1px solid $order-item-border-color;

  @include media-breakpoint-down(sm) {
    cursor: pointer;
  }

  &,
  &--light,
  &--dark {

    .act-order-item__history-col.act-order-item__history-col--first {

      @include media-breakpoint-up(md) {
        background-color: $body-bg;
      }
    }
  }

  &--light {

    .act-order-item__history-col {
      background-color: $order-item-row-bg--light;
    }
  }

  &--dark {

    .act-order-item__history-col {
      background-color: $order-item-row-bg--dark;
    }
  }

  > div {

    .act-order-item__history-col {
      border-bottom: 1px solid $order-item-border-color;
    }

    &:first-child {

      .act-order-item__history-col {
        border-left: 1px solid $order-item-border-color;

        @include media-breakpoint-up(md) {
          border-bottom: none;
          border-left: none;
        }
      }
    }

    @include media-breakpoint-up(md) {

      &:nth-child(2) {

        .act-order-item__history-col {
          border-left: 1px solid $order-item-border-color;
        }
      }
    }
  }
}

.act-order-item__history-col {
  height: 100%;

  &--first {
    border-left: 1px solid $order-item-border-color;
    color: $text-muted;

    @include media-breakpoint-up(md) {
      background-color: $body-bg;
      border-left: none;
      text-align: right;
    }
  }
}

.act-order-item__history-collapse-btn {
  padding: 0 $space1;
}

.act-order-item__history-collapsed-row {
  width: 100%;
  padding: $space3 0;
  background-color: $white;
  border: 1px solid $order-item-border-color;
  border-top: none;
}

.act-order-item__history-collapsed-col {
  display: flex;
  flex-direction: column;
  padding: $space1 $space3;
}
