/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

// Header

.act-header {

  &,
  .row {
    background-color: $header-bg-color;
  }

  &,
  a {
    color: $header-color;
  }
}

.act-header__brand,
.act-header__order,
.act-header__contact {
  background-color: $header-bg-color;
}

.act-header__order,
.act-header__contact {
  border-color: $header-color;
}

.act-order-header {
  background-color: $order-header-bg-color;
  color: $order-header-color;
}

.act-order-item__collapse-btn {

  &,
  &:focus,
  &:active,
  &:hover {
    color: $accent-color;
  }

  .act-icon-arrow {

    path {
      stroke: $accent-color;
    }
  }
}

.act-order-item__col-label {
  color: $accent-color;
}

.act-order-item__history-col {

  .act-icon-arrow {

    path {
      stroke: $text-muted;
    }
  }
}

.act-pagination-item__link {
  color: $accent-color;
}

.act-pagination-item--active .act-pagination-item__link {
  background-color: $accent-bg-color;
  color: $header-color;
}

.act-error {

  h2,
  p {
    color: $accent-color;
  }
}
