/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--rodcraft {
  $primary: #002E4E;
  $secondary: #FFDD00;
  $header-color: $primary;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $primary;
  $accent-bg-color: $primary;

  .act-header__logo {
    background-image: url("../assets/brands/rodcraft/logo.jpg");
  }

  @import "../brand-template";
}
