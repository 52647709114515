/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--epiroc {
  $primary: #FFC72C;
  $secondary: #425563;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/epiroc/logo.png");
  }

  @import "../brand-template";
}
