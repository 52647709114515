/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-pagination {
  display: flex;
  margin: $space2 0;
  padding-left: 0;
  list-style: none;
  justify-content: flex-end;
}

.act-pagination-item {
  margin: 1px;
}

.act-pagination-item__link {
  display: block;
  min-width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  background-color: $order-item-row-bg--dark;
  font-size: $fs14;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  text-indent: -2px;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  .disabled & {
    cursor: auto;
    pointer-events: none;
  }

  @include media-breakpoint-up(md) {
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: $fs16;
  }
}
