/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--beaconmedaes {
  $primary: #0070c0;
  $secondary: #ffffff;
  $header-color: $black;
  $header-bg-color: $secondary;
  $order-header-color: $black;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/beaconmedaes/logo.png");
  }

  @import "../brand-template";
}
