/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-footer {
  margin-top: $space3;
  margin-bottom: 0;
  padding-top: $space3;
  border-top: 1px solid $order-item-border-color;
  color: $text-muted;

  @include media-breakpoint-up(md) {
    margin-bottom: $space3;
  }
}

.act-footer__break {
  margin: 0 $space2;
}

.act-footer__legal-notice {

  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $text-muted;
    font-weight: bold;
  }
}
