/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--ekomak{
  $primary: #000000;
  $secondary: #ed8b00;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/ekomak/logo.svg");
  }

  @import "../brand-template";
}
