/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--alup {
  $primary: #0071bc;
  $secondary: #f3f3f3;
  $header-color: $black;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $black;
  $accent-bg-color: #e6e6e6;

  .act-header__logo {
    background-image: url("../assets/brands/alup/logo.svg");
  }

  @import "../brand-template";
}
