/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--fiac {
  $primary: #41b6e6;
  $secondary: #212121;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/fiac/logo.svg");
  }

  @import "../brand-template";
}
