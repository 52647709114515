/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

/*
 Override Bootstrap variables
 */

// Typography
$font-family-base: 'Lato', sans-serif;

// Colors
$body-bg: #f9f9f9;
$text-muted: #858585;
$order-item-border-color: #ebebeb;
$order-item-row-bg--light: #f4f4f4;
$order-item-row-bg--dark: #f1f1f1;

// Links
$link-color: #0098be;
$link-decoration: none;
$link-hover-color: $link-color;
$link-hover-decoration: underline;

// Spacing
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    2a: ($spacer * .75),
    3: $spacer,
    3a: ($spacer * 1.25),
    4: ($spacer * 1.5),
    4a: ($spacer * 1.75),
    4b: ($spacer * 2),
    4c: ($spacer * 2.25),
    4d: ($spacer * 2.5),
    4e: ($spacer * 2.75),
    5: ($spacer * 3)
  ),
  $spacers
);

// Grid
$grid-gutter-width: map-get($spacers, 3a) * 2;
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1200px
);


/*
 Custom variables
 */

// Spacing
$space0: 0;
$space1: map-get($spacers, 1);
$space2: map-get($spacers, 2);
$space2a: map-get($spacers, 2a);
$space3: map-get($spacers, 3);
$space3a: map-get($spacers, 3a);
$space4: map-get($spacers, 4);
$space4a: map-get($spacers, 4a);
$space4b: map-get($spacers, 4b);
$space4c: map-get($spacers, 4c);
$space4d: map-get($spacers, 4d);
$space4e: map-get($spacers, 4e);
$space5: map-get($spacers, 5);

// Font-sizes
$font-size-base: 1rem;
$fs10: $font-size-base * .625; // 10px
$fs11: $font-size-base * .6875; // 11px
$fs12: $font-size-base * .75; // 12px
$fs13: $font-size-base * .8125; // 13px
$fs14: $font-size-base * .875; // 14px
$fs16: $font-size-base; // 16px
$fs18: $font-size-base * 1.125; // 18px
$fs20: $font-size-base * 1.25; // 20px
$fs24: $font-size-base * 1.5; // 24px
$fs28: $font-size-base * 1.75; // 28px
$fs32: $font-size-base * 2; // 32px
$fs40: $font-size-base * 2.5; // 40px
