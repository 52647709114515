/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-header {

  @include media-breakpoint-up(lg) {
    padding: $space3a 0;
  }

  @include media-breakpoint-up(md) {
    padding-top: $space3a;
  }

  @include media-breakpoint-down(md) {
    background: none !important;
  }

  .container {

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

.act-header__brand,
.act-header__order,
.act-header__contact {
  display: flex;
  height: 100%;
  padding: $space3a;

  @include media-breakpoint-up(lg) {
    padding: 0;
    background: none;
  }
}

.act-header__brand {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-only(md) {
    flex-direction: column;
  }

  h1 {
    margin-bottom: 0;
    font-weight: bold;
    font-size: $fs24;

    @include media-breakpoint-down(md) {
      font-size: $fs20;
    }

    @include media-breakpoint-up(lg) {
      margin-right: $space3;
    }
  }
}

.act-header__logo {
  width: 10rem;
  height: 2.75rem;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  @include media-breakpoint-only(md) {
    background-position: top center;
  }

  @include media-breakpoint-up(lg) {
    width: 15rem;
    height: 3.75rem;
  }
}

.act-header__order,
.act-header__contact {
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid;

  @include media-breakpoint-up(md) {
    border-top: none;
    border-left: 2px solid;
  }

  @include media-breakpoint-up(lg) {
    border-left: 1px solid;
    margin-left: $space4;
    padding-left: $space4d;
  }
}

.act-header__order-nr-label,
.act-header__contact-label {
  font-size: $fs16;

  @include media-breakpoint-up(lg) {
    font-size: $fs18;
  }
}

.act-header__order-nr {
  font-size: $fs20;
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    font-size: $fs24;
  }
}

.act-header__purchase-nr-label,
.act-header__purchase-nr {
  font-size: $fs12;

  @include media-breakpoint-up(lg) {
    font-size: $fs14;
  }
}

.act-header__purchase-nr {
  font-weight: bold;
  margin-left: $space2;
}

.act-header__contact {

  div {
    margin-bottom: $space1;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.act-header__contact-name,
.act-header__contact-email,
.act-header__contact-telephone {
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: $fs12;
  }
}
