/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-order-header {
  margin-bottom: $space3;
  border: 1px solid $white;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.act-order-header__item {
  height: 100%;
  padding: $space3;
  border-left: 2px solid $white;
  font-weight: bold;

  &--first {
    border-left: none;
  }
}
