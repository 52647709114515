/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--mauguiere {
  $primary: #427CBF;
  $secondary: #8DC63F;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/mauguiere/logo.svg");
  }

  @import "../brand-template";
}
