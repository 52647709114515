/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.act-main {
  padding: $space3a 0;
  font-size: $fs12;

  @include media-breakpoint-up(lg) {
    font-size: $fs14;
  }
}

.act-error {
  margin-top: $space4;

  h2 {
    font-weight: bold;
  }

  p {
    font-size: $fs16;

    @include media-breakpoint-up(lg) {
      font-size: $fs18;
    }
  }
}

footer {
  margin-bottom: $space4;
}

/*
 * OneTrust banner styling
 */
#onetrust-consent-sdk {
    #onetrust-button-group button#onetrust-accept-btn-handler,
    #onetrust-button-group button#onetrust-pc-btn-handler,
    #onetrust-pc-sdk button {
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;

        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        transition-property: background, color, border;

        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #onetrust-banner-sdk, #onetrust-pc-sdk {
        border-radius: 10px;
    }

    #onetrust-pc-sdk h3 {
        &#manage-cookies-text, &#pc-title {
            color: #0078A1;
        }
    }
}

