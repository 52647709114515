/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--ceccato {
  $primary: #0078ae;
  $secondary: #00b259;
  $header-color: $white;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $secondary;
  $accent-bg-color: $secondary;

  .act-header__logo {
    background-image: url("../assets/brands/ceccato/logo.svg");
  }

  @import "../brand-template";
}
