/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

.act-icon-arrow {
  width: .5em;
  height: 1em;

  &--top {
    transform: rotate(90deg);
  }

  &--right {
    transform: rotate(180deg);
  }

  &--bottom {
    transform: rotate(-90deg);
  }

  &--left {
    transform: none;
  }
}
