/*!
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

body.brand--mark {
  $primary: #11a0e4;
  $secondary: #f3f3f3;
  $header-color: #373D41;
  $header-bg-color: $secondary;
  $order-header-color: $white;
  $order-header-bg-color: $primary;
  $accent-color: $header-color;
  $accent-bg-color: #e6e6e6;

  .act-header__logo {
    background-image: url("../assets/brands/mark/logo.svg");
  }

  @import "../brand-template";
}
